import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FaCalendarAlt } from "react-icons/fa"

const BlogRoll = ({
  posts,
  limit,
  skip,
  currentPage,
  numPages,
}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()
  return (
    <div>
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }, i) => {
            return (
              <div className="is-parent column is-4" key={post.id}>
                <article >
                  <header>
                    <p className="post-meta">
                      <Link
                        className="title is-size-5"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>

                    </p>
                    <p className="post-date">
                      <FaCalendarAlt />
                      <span>
                        {post.frontmatter.date}
                      </span>
                    </p>
                  </header>
                  <p className="post-description">
                    {post.excerpt}
                  </p>
                  <p style={{ textAlign: "right" }}>
                    <Link to={post.fields.slug}>
                      <button style={{ background: "#53c5d1" }}>Read More</button>
                    </Link>
                  </p>
                </article>
              </div>
            )

          }
          )}
      </div>
      <div className="has-text-centered pager" >
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Prev
          </Link>
        )}
        {Array.from({ length: numPages }, (_, i) => (
          <Link key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}`}>
            {i + 1}
          </Link>
        ))}
        {!isLast && (
          <Link to={nextPage} rel="next">
            Next →
          </Link>
        )}
      </div>
    </div>
  )
}
BlogRoll.propTypes = {
  posts: PropTypes.array,
  limit: PropTypes.number,
  skip: PropTypes.number,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
}

export default BlogRoll
