import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import AboveFooter from "../components/AboveFooter"
import BlogRoll from "../components/BlogRoll"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

export const BlogPageTemplate = ({
  crumbs,
  seo,
  hero,
  fixedimagesection,
  location,
  limit,
  skip,
  currentPage,
  numPages,
  posts
}) => {
  const disableLinks = [location.pathname]
  return (
    <div>
      <SEO title={seo.title} description={seo.description} blogservice={true} blogonly={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="breadcrumbs">
            <Breadcrumb
              crumbs={crumbs}
              // crumbLabel="Blog"
              disableLinks={disableLinks}
            />
          </div>
          <div className="has-text-centered">
            <p style={{ margin: "2rem 0" }}>WE PROVIDE A SERIES OF ARTICLES TO HELP YOU NAVIGATE THE WORLD OF CYBER SECURITY</p>
          </div>
          <BlogRoll posts={posts} limit={limit} skip={skip} currentPage={currentPage} numPages={numPages} />
        </div>
      </section>
      <AboveFooter data={fixedimagesection} />
    </div>
  )
}

BlogPageTemplate.propTypes = {
  crumbs: PropTypes.array,
  seo: PropTypes.object,
  hero: PropTypes.object,
  fixedimagesection: PropTypes.object,
  location: PropTypes.object,
  limit: PropTypes.number,
  skip: PropTypes.number,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  posts: PropTypes.array
}

const BlogPage = ({
  pageContext: {
    breadcrumb: { crumbs },
    limit,
    skip,
    currentPage,
    numPages,
  },
  location,
  data,

}) => {
  const { frontmatter } = data.blogpage
  const { edges: posts } = data.posts
  return (
    <Layout>
      <BlogPageTemplate
        crumbs={crumbs}
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        fixedimagesection={frontmatter.fixedimagesection}
        location={location}
        limit={limit}
        skip={skip}
        currentPage={currentPage}
        numPages={numPages}
        posts={posts}
      />
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    blogpage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    posts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPage

export const pageQuery = graphql`
  query BlogPageTemplate($skip: Int, $limit: Int) {
    blogpage: markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 120)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }

  }
`
